import EditHome from "../../Components/leflet/LafLeat";

export default function IndexPage() {
	return (

		<div style={{ width: "100%", height: "500px" }}>
			{" "}
			{/* <EditHome /> */}
			<h1>Home</h1>
		</div>

	);
}
