export const ERROR_TEXT = 'Xatolik';
export const ERROR_FETCH = 'Ma`lumotlar olishda xatolik';
export const ERROR_POST_FETCH = 'Ma`lumotlar saqlashda xatolik';
export const ERROR_UPDATE_FETCH = 'Ma`lumotlar o`zgartirishda xatolik';
export const ERROR_DELETE_FETCH = 'Ma`lumotni o`chirishda xatolik';

export const ARE_YOU_SURE = 'Ishinchingiz komilmi';
export const CHANGED = 'O`zgartirildi!';
export const ADDED = "Qo'shildi";
export const DATA_CANNOT = "O'chirgandan keyin ma'lumotlarni tiklab bo'lmaydi";
export const DELETED = 'O`chirildi';
export const YES = 'Ha';
export const NO = 'Yo`q';
export const IMAGE_UPLOADED = 'Rasim yuklandi';
export const IMAGE_NOT_UPLOADED = 'Xatolik rasim yuklanmadi.';
export const TRANSLATION_UPDATED = 'Tarjima yangilandi';
export const TRANSLATION_NOT_UPDATED = 'Tarjimani yangilashda hatolik';
export const NO_INFORMATION_FOUND = "Ma`lumot topilmadi!";

// Swal icon
export const ERROR = 'error';
export const SUCCESS = 'success';
export const WARNING = 'warning';

//Swal button color
export const CONFIRMBUTTONCOLOR = '#3085d6';
export const CANSELMBUTTONCOLOR = '#d33';

// route path
export const REDIRECT_TO_LOGIN = '/login';
